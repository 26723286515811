import {AppSettings} from "./app.settings";

export class Utilities {

  public static diferenciasAsdeEntity(query: string): string {
    let auxQuery: string = query;
    if (query.toUpperCase().startsWith("AS")) {
      auxQuery = query.substring(2);
      if (isNaN(Number(auxQuery)) == false) {
        return auxQuery;
      } else {
        return query;
      }
    };
    return auxQuery;
  }; 

  public static queryIrr(query: string, flags: boolean): string {
    let auxQuery: string;
    
    if (flags) {
      auxQuery = query;
    } else {
      auxQuery = query.toUpperCase();
    }
    
    if (isNaN(Number(auxQuery)) == false) {
        return "AS" + auxQuery;
    };
    return auxQuery;
  }; 

  public static  truncarRespuesta(strRpta: string): string {
		let respuesta: string = strRpta;
		if (respuesta.length > 5000) {
			respuesta = respuesta.substring(0, 5000);
			respuesta = respuesta.concat(AppSettings.RESPUESTA_TRUNCADA);
		}
		return respuesta;
  }

  public static queryRDAP(query: string): string {
    let auxQuery: string = "";
    let auxQuery2: string = "";

    if (query.toUpperCase().startsWith("AS")) {
      auxQuery2 = query.toUpperCase().replace(AppSettings.AS, "");
      if (isNaN(Number(auxQuery2)) == false) {
        auxQuery = AppSettings.ENDPOINT_AUTNUM + auxQuery2;
      } else {
        auxQuery = AppSettings.ENDPOINT_ENTITY + query;
      }
    } else {
      if (isNaN(Number(query)) == false) {
        auxQuery = AppSettings.ENDPOINT_AUTNUM + query;
      } else {
        if (query.includes(".") || query.includes(":")) {
          auxQuery = AppSettings.ENDPOINT_IP + query;
        } else {
          auxQuery = AppSettings.ENDPOINT_ENTITY + query;
        }
      }
    } 
   
    return auxQuery;
  }; 
  

  public static validoInputIrr(valor: string): string {
    let errorStr: string = '';
    if (valor.length < 3) {
      errorStr = "GENERAL.Errores.LargoMin";      
    }
    if (valor.length > 30) {
      errorStr = "GENERAL.Errores.LargoMax";
    }
    if ((valor.startsWith('-'))||(valor.includes('!'))||(valor.includes(' -'))||(valor.startsWith(' ')) ||(valor.endsWith(' '))) {
      errorStr = "GENERAL.Errores.CaracterInvalido";
    }

    return errorStr
  }

  public static validoInputIrrTipoValor(valor: string): string {
    let errorStr: string = '';
    let regex: string = '[-a-zA-Z0-9]*\\s[-a-zA-Z0-9]*';
    var patt = new RegExp(regex);
    this.log("valoe:"+ valor);
    //console.log(patt.test(valor));
    if (!patt.test(valor)) {
      errorStr = 'GENERAL.Errores.ErrorTipoValor';
    }
    return errorStr;
  }

  public static log(texto : string){
      if(AppSettings.debug){
          console.log(texto);
      }
  }
}