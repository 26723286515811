<div>
  <footer class="footer footer-default">
		<div class="container">
			<nav class="float-left">
				<ul>
					<li><a id="wwwlacnicnet" target="_blank" href={{linkLacnic}}> LACNIC </a></li>
					<li><a id="milacnic" target="_blank" href={{linkMiLacnic}}> MiLACNIC </a></li>
					<li><a id="rdapweb" target="_blank" href={{linkRdap}}> RDAP </a></li>
					<li>
            <a id="politicaprivacidad" target="_blank" href={{linkPolitica}}>
              {{ 'FOOTER.Links.PoliticaPrivacidad' | translate }}
            </a>
          </li>
				</ul>
			</nav>
			<div class="copyright float-right">
				{{ 'FOOTER.Textos.Copyright' | translate }} 
        <a id="hackathon" target="_blank" href={{linkHackaton}}>{{ 'FOOTER.Links.Copyright' | translate }}</a> - 
        <a id="agradecimientos" target="_blank" routerLink="/special-thanks">
          {{ 'FOOTER.Links.Thanks' | translate }}
        </a>
			</div>
		</div>
	</footer>
</div>
