import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { IrrComponent } from './irr/irr.component';
import { SpecialThanksComponent } from './special-thanks/special-thanks.component';
import { NotFoundComponent } from './not-found.component';

const routes: Routes = [
  { path: 'query/home', redirectTo: '/home', pathMatch: 'full'},
  { path: 'query/search', redirectTo: '/search', pathMatch: 'full'},
  { path: 'query/irr', redirectTo: '/irr', pathMatch: 'full'},
  { path: 'query/special-thanks', redirectTo: '/special-thanks', pathMatch: 'full'},
  { path: 'home', component: HomeComponent },
  { path: 'search', component: SearchComponent },
  { path: 'irr', component: IrrComponent },
  { path: 'special-thanks', component: SpecialThanksComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent}

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
