<div class="page-header header-filter" data-parallax="true"
		style="background-image: url('assets/img/bg2.jpg')">
  <div class="container">
    <h1>
      404
    </h1>
    <h3 class="font-bold">
      {{ 'GENERAL.Errores.Err404' | translate }}
    </h3>
    <a id="linkHome" class="badge badge-default" routerLink="/home">
      <h4>{{ 'MENU.Links.Home' | translate }}</h4>
    </a>
  </div>
</div>
