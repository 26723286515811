<div class="page-header header-filter" data-parallax="true"
		style="background-image: url('assets/img/bg1.jpg')">
		<div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 class="title">MiLACNIC Query</h1>
          <h4>
            {{ 'THANKS.Textos.Header' | translate }}
          </h4>
        </div>
      </div>
		</div>
</div>
<div class="main main-raised">
  <div class="container">
		<div class="team-2" id="team-2">
			<div class="container">
				<div class="row">
					<div class="col-md-8 ml-auto mr-auto text-center">
						<h2 class="title">{{ 'THANKS.Textos.Hackathon' | translate }}</h2>
						<h5 class="description">{{ 'THANKS.Textos.Equipo' | translate }}</h5>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">César Alejandro Ayala Torres</h4>
								<h6 class="card-category text-muted">Truxgo - México</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:cesar@corp.truxgo.com"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://corp.truxgo.com/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>


					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Charly Gréaux</h4>
								<h6 class="card-category text-muted">Glabtech - Francia</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:grx@glabtech.com"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.glabtech.com"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>


					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">José Restaino</h4>
								<h6 class="card-category text-muted">ANTEL - Uruguay</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:jrestaino@antel.com.uy"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.antel.com.uy/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Leandro Ríos</h4>
								<h6 class="card-category text-muted">Tecnoazar - Argentina</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:leandroRios@tecnoazar.com.ar"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.tecnoazar.com.ar"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>



					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Lía Solí­s Montaño</h4>
								<h6 class="card-category text-muted">ISOC Bolivia - Bolivia</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:lsolis@entelnet.bo"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://isoc.org"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>




					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Miguel Sánchez</h4>
								<h6 class="card-category text-muted">TEISA - Paraguay</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:msanchez@teisa.com.py"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.teisa.com.py/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>


					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Sergio Rojas</h4>
								<h6 class="card-category text-muted">LACNIC - Paraguay</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:sergio@lacnic.net"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.lacnic.net/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>




					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Simón Pérez Córdova</h4>
								<h6 class="card-category text-muted">Centurylink - Panamá</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:simon.perez@centurylink.com"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.centurylink.com/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>


					<div class="col-md-4">
						<div class="card card-profile card-plain">
							<div class="card-body ">
								<h4 class="card-title">Tanner Moreno</h4>
								<h6 class="card-category text-muted">Ovnicom - Panamá</h6>
							</div>
							<div class="card-footer justify-content-center">
								<a href="mailto:tmoreno@ovnicom.com"
									class="btn btn-just-icon btn-twitter btn-round"><i
									class="fa fa-envelope"></i></a> <a href="http://www.ovnicom.com/"
									class="btn btn-just-icon btn-dribbble btn-round"><i
									class="fa fa-dribbble"></i></a>
							</div>
						</div>
					</div>






				</div>
			</div>
		</div>
	</div>
</div>