import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings} from '../app.settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  linkMiLacnic: string = AppSettings.MILACNIC;
  linkLacnic: string = AppSettings.WWWLACNICNET;
  linkRdap: string = AppSettings.HTTPS_RDAP_WEB_LACNIC_NET;
  linkPolitica: string = AppSettings.POLITICAPRIVACIDAD;
  linkHackaton: string = AppSettings.HACKATHON; 

  constructor(private translate: TranslateService) { 
    this.cargarLenguaje();
  }

  ngOnInit(): void {
  }

  cargarLenguaje(){
   
    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    
    if(localStorage.getItem('lenguaje') != null) {
      this.translate.use(localStorage.getItem('lenguaje'));
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }
    
  }

}
