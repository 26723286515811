import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  error: boolean = false; 
  errorStr: string = "";

  query: string;

  constructor(private router: Router,private translate: TranslateService) { 
    this.cargarLenguaje();
  }

  cargarLenguaje(){
   
    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    
    if(localStorage.getItem('lenguaje') != null) {
      this.translate.use(localStorage.getItem('lenguaje'));
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }
    
  }

  ngOnInit(): void {
  }

  onClickSearch(): void {   
    this.error = false;
    this.errorStr = "";

    if(this.query == null || this.query == ""){
      this.error = true;
      this.errorStr =  this.translate.instant("GENERAL.Errores.Vacio");
    } else{
      //const navigationExtras: NavigationExtras = {state: {search: this.query}};
      //this.router.navigate(['/search'], navigationExtras);
      this.router.navigate(['/search'], { queryParams: { id: this.query } });
    }
  }

}
