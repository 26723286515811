<div class="page-header header-filter header-small" data-parallax="true"
		style="background-image: url('assets/img/bg1.jpg')">
  <div class="container top-container">
    <div class="row">      
      <div class="col-md-3">
        <h1 class="title">MiLACNIC Query</h1>
        <h4 class="title">{{ 'IRR.Textos.Header' | translate }}</h4>
      </div>

      <div class="col-md-9 ml-auto m  r-auto">
        
        <form id="form">

          <div class="card card-contact card-raised">
            <div class="card-body ">                
      
              <div class="form-row">
                <div class="col-8">
                  <input 
                    type="text" 
                    id="textkey" 
                    class="form-control"
                    placeholder="e.g. MNT-UY-ANTA-LACNIC"
                    [(ngModel)]="strIdentificador"
                    name="strIdentificador"
                    (change)="onChangeFlags('identificador')" 
                  > 
                  <small class="form-text text-muted">&nbsp;&nbsp;{{ 'IRR.Textos.Identificador' | translate }}</small>
                </div>
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label"> 
                      <input
                        class="form-check-input" 
                        id="checkk" 
                        type="checkbox"
                        value=""
                        [(ngModel)]="flgK" 
                        name="flgK"                      
                      > {{ 'IRR.Textos.FlagK' | translate }}
                      <span class="form-check-sign"> 
                        <span class="check"></span>
                      </span>
                    </label> 
                    <small class="form-text text-muted">
                      <strong>-k</strong> {{ 'IRR.Textos.SoloIdentif' | translate }}
                    </small>
                  </div>
                </div>
              </div>        
              <div class="form-row">
                <div class="col">
                  <input 
                    type="text" 
                    id="textx" 
                    class="form-control"
                    placeholder="e.g. 192.0.2.0/24"
                    [(ngModel)]="strIPs"
                    name="strIPs"
                    (change)="onChangeFlags('strIPs')" 
                  > 
                  <small class="form-text text-muted">
                    <strong>-x </strong>{{ 'IRR.Textos.BuscarIPs' | translate }}
                  </small>
                </div>                       
                <div class="col">
                  <input 
                    type="text" 
                    id="textL" 
                    class="form-control"
                    placeholder="e.g. 192.0.2.0/24"
                    [(ngModel)]="strIPsMenosEspecif"
                    name="strIPsMenosEspecif"
                    (change)="onChangeFlags('strIPsMenosEspecif')"
                  > 
                  <small class="form-text text-muted">
                    <strong>-L </strong>{{ 'IRR.Textos.IPsMenosEsp' | translate }}
                  </small>
                </div>        
                <div class="col">
                  <input 
                    type="text" 
                    id="textM" 
                    class="form-control"
                    placeholder="e.g. 192.0.2.0/24"
                    [(ngModel)]="strIPsMasEspecif"
                    name="strIPsMasEspecif"
                    (change)="onChangeFlags('strIPsMasEspecif')"
                  > 
                  <small class="form-text text-muted">
                    <strong>-M </strong>{{ 'IRR.Textos.IPsMasEsp' | translate }}
                  </small>        
                </div>
              </div>        
              <div class="form-row">
                <div class="col">
                  <select 
                    id="textt" 
                    class="form-control"
                    [(ngModel)]="strTemplate"
                    name="strTemplate"
                    (change)="onChangeFlags('template')"
                  > 
                    <option value=""></option>                   
                    <option *ngFor="let arrIrrObj of arrIrrObjs" [value]="arrIrrObj.value">  
                      {{arrIrrObj.display}}
                    </option>
                  </select>
                  <small  class="form-text text-muted">
                    <strong>-t </strong>{{ 'IRR.Textos.Templates' | translate }}
                  </small>
                </div>
                <div class="col">
                  <input 
                    type="text" 
                    id="texts" 
                    class="form-control"
                    placeholder="e.g. LACNIC"
                    [(ngModel)]="strFuente"
                    name="strFuente"
                    (change)="onChangeFlags('strFuente')"
                  > 
                  <small class="form-text text-muted">
                    <strong>-s </strong>{{ 'IRR.Textos.Fuente' | translate }}
                  </small>
                </div>
                <div class="col">
                  <input 
                    id="texti" 
                    type="text" 
                    class="form-control"
                    placeholder="eg. origin AS23456"
                    [(ngModel)]="strTipoValor"
                    name="strTipoValor"
                    (change)="onChangeFlags('strTipoValor')"
                  > 
                  <small class="form-text text-muted">
                    <strong>-i </strong>{{ 'IRR.Textos.TipoValor' | translate }}
                  </small>
                </div>
              </div>        
              <div class="form-row">
                <div id="tags" class="bootstrap-tagsinput success-badge">
                  <div *ngIf="flgIdent" (click)="onClear('identificador')">
                    <a class="tag badge-primary" id="linkkeyRemover">{{strIdentificador}}
                      <span id="labelkey"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div> 
                  <div *ngIf="flgX" (click)="onClear('strIPs')">
                    <a class="tag badge-primary" id="linkxRemover">-x {{strIPs}} 
                      <span id="labelx"></span>
                      <span data-role="remove"></span>
                    </a>                  
                  </div>                 
                  <div *ngIf="false" >
                    <a class="tag badge-primary" id="linklessRemover">-l 
                      <span id="labelless"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>
                  <div *ngIf="flgL" (click)="onClear('strIPsMenosEspecif')">
                    <a class="tag badge-primary" id="linkLRemover">-L {{strIPsMenosEspecif}} 
                      <span id="labelL"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>
                  <div *ngIf="flgM" (click)="onClear('strIPsMasEspecif')">
                    <a class="tag badge-primary" id="linkMRemover">-M {{strIPsMasEspecif}}
                      <span id="labelM"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>
                  <div *ngIf="flgTempl" (click)="onClear('strTemplate')">
                    <a class="tag badge-primary" id="linktRemover">-t {{strTemplate}}
                      <span id="labelt"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>
                  <div *ngIf="flgS" (click)="onClear('strFuente')">
                    <a class="tag badge-primary" id="linksRemover">-s {{strFuente}}
                      <span id="labels"></span>
                      <span data-role="remove"></span>
                    </a>
                  </div> 
                  <div *ngIf="flgTipoValor" (click)="onClear('strTipoValor')">
                    <a class="tag badge-primary" id="linkiRemover">-i {{strTipoValor}}
                      <span id="labeli"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>
                  <div *ngIf="flgK" (click)="onClear('flgK')">
                    <a class="tag badge-primary" id="linkkRemover">-K 
                      <span id="labelk"></span>
                      <span data-role="remove"></span>
                    </a> 
                  </div>       
                </div>
              </div>
              <div class="card-footer justify-content-between">
                <div class="col-md-6 ml-auto mr-auto">
                  <input 
                    type="hidden" 
                    name="recaptcha_response"
                    id="recaptchaResponse" 
                  >        
                  <button 
                    
                    class="btn btn-primary btn-block"
                    id="buscar"
                    (click)="onClickBtnSearch()"
                  >{{ 'IRR.Botones.Buscar' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
          
      </div>

    </div>


    
  </div>
</div>



<div class="main main-raised">
  <div *ngIf="error">
		<ul class="feedbackPanel">
			<li class="feedbackPanelERROR">
				<span>{{errorStr}}</span>
			</li>
		</ul>
	</div>
  <div class="section section-basic" id="no.result" *ngIf="!loading && noResultados">
    <div class="container">    
      <div class="col-md-12  ml-auto mr-auto text-center">
        <h5>
          {{ 'IRR.Textos.Ayuda' | translate }}<a
            id="documentacion" target="_blank" href={{linkDocumentacion}}>{{ 'IRR.Botones.Mail' | translate }}</a>{{ 'IRR.Textos.Esribenos' | translate }}
          <a href="mailto:hostmaster@lacnic.net"  target="_blank">hostmaster@lacnic.net</a>
        </h5>
      </div>
    </div>
  </div>

  <div class="section section-basic" id="result" *ngIf="!loading && !noResultados">
		<div class="container">

			<div class="col-md-12">
				<div class="row">
					<div class="col-md-2">
            <ul
              class="nav nav-pills nav-pills-gray nav-pills-icons flex-column"
              role="tablist"
            >
              <li class="nav-item">
                <a class="nav-link active"                  
                  role="tab" 
                  data-toggle="tab"
                >
                  <div class="media">
                    <div class="avatar">
                      <img class="img" src="assets/img/iconos_irr.jpg">
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
					<div class="col-md-8">
						<div class="tab-content">			
              <div class="tab-pane active" id="tIrr">
								<div class="blockquote blockquote-small">
									<pre id="irr">{{infoIrr}}</pre>
								</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class=" text-center m-t-xs m-b-sm">
    <img alt="logo" src="assets/img/1.gif" />
  </div>
</div>
  

