import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppSettings} from '../app.settings';
import { Utilities } from '../utilities';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {

  linkDoc: string = AppSettings.ZENDESK;
  linkTools: string = AppSettings.TOOLS;
  linkMiLacnic: string = AppSettings.MILACNIC;

  idiomaActual : string;

  constructor(private translate: TranslateService) { 
    this.cargarLenguaje();
  }

  ngOnInit(): void {
    
  }

  cargarLenguaje(){
    Utilities.log("[Top-Menu] - cargarLenguaje: Start");

    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    this.idiomaActual = 'es';
    if(localStorage.getItem('lenguaje') != null) {
        var idioma : string = localStorage.getItem('lenguaje');
        this.translate.use(idioma);
        this.idiomaActual = idioma;
    }

    Utilities.log("[Top-Menu] - cargarLenguaje: Finish");
  }

  cambiarLenguaje(lenguaje : string){
    Utilities.log("[Top-Menu] - cambiarLenguaje: Start");

    localStorage.setItem("lenguaje",lenguaje);
    this.translate.use(lenguaje);
    this.idiomaActual = lenguaje;

    Utilities.log("[Top-Menu] - cambiarLenguaje: Finish");
  }

}
