<div class="page-header header-filter header-small" data-parallax="true"
		style="background-image: url('assets/img/bg1.jpg')">
  <div class="container">
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto text-center">
        <h1 class="title">MiLACNIC Query</h1>
      </div>      
      <div class="col-md-8 ml-auto mr-auto">
        <div class="card card-raised card-form-horizontal">
          <div class="card-body ">
          <!--<form id="form"> -->
              <div class="row">
                <div class="col-md-8">
                  <span class="bmd-form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"> 
                          <i class="material-icons">search</i>
                        </span>
                      </div>
                      <input 
                        type="text"
                        placeholder="IPs, ASN, Rdap Objects, Rpsl Objects..."
                        class="form-control"  
                        id="object"
                        [(ngModel)]="query"
                        name="query"
                      >
                        
                      <!--<input 
                        type="hidden" 
                        name="recaptcha_response" 
                        id="recaptchaResponse">-->
      
                    </div>
                  </span>
                </div>
                <div class="col-md-4">
                  <button                     
                    class="btn btn-primary btn-block"                    
                    (click)="onClickBtnSearch()"
                  >{{ 'SEARCH.Botones.Buscar' | translate }}</button>
                </div>
              </div>
            <!--</form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main main-raised">
  <div *ngIf="error">
		<ul class="feedbackPanel">
			<li class="feedbackPanelERROR">
				<span>{{errorStr}}</span>
			</li>
		</ul>
	</div>
 
  <div class="section section-basic" id="no.result" *ngIf="!loading && noResultados">
    <div class="container">

      <div class="col-md-12  ml-auto mr-auto text-center">
        <h5>
          {{ 'SEARCH.Textos.Ayuda' | translate }}<a
            id="documentacion" target="_blank" href={{linkDocumentacion}}>{{ 'SEARCH.Botones.Mail' | translate }}</a>{{ 'SEARCH.Textos.Esribenos' | translate }}
          <a href="mailto:hostmaster@lacnic.net"  target="_blank">hostmaster@lacnic.net</a>
        </h5>
      </div>
    </div>
  </div>
  
  
  <div *ngIf="loading" class=" text-center m-t-xs m-b-sm">
    <img alt="logo" src="assets/img/1.gif" />
  </div>
  
  <div class="section section-basic" id="result" *ngIf="!loading && !noResultados">
		<div class="container">

			<div class="col-md-12">
				<div class="row">
					<div class="col-md-2">
						<ul
							class="nav nav-pills nav-pills-info nav-pills-icons flex-column"
              role="tablist"
              >
						  <li class="nav-item">
                <a 
                  class="nav-link " 
                  [class.active]="selectedTab === 'WHOIS'"
                  (click)="onSelect('WHOIS')" 
                  role="tab" 
                  data-toggle="tab"
                >
									<div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_whois.jpg">
										</div>
									</div>
                </a>
              </li>							
							<li class="nav-item">
                <a class="nav-link " 
                  (click)="onSelect('IRR')" 
                  [class.active]="selectedTab === 'IRR'"
                  role="tab" 
                  data-toggle="tab"
                >
									<div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_irr.jpg">
										</div>
									</div>
                </a>
              </li>
							<li class="nav-item">
                <a class="nav-link " 
                  [class.active]="selectedTab === 'RDAP'"
                  (click)="onSelect('RDAP')"	
                  role="tab" 
                  data-toggle="tab"
                >
                  <div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_rdap.jpg">
										</div>
                  </div> 
                </a>
              </li>
							<li class="nav-item">
                <a 
                  class="nav-link " 
                  (click)="onSelect('RDAPWEB')" 
                  [class.active]="selectedTab === 'RDAPWEB'"
                  role="tab" 
                  [href]="urlRDAPWeb" target="_blank"
                >
									<div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_rdapweb.jpg">
										</div>
									</div>
                </a>
              </li>		
              
              <li class="nav-item">
                <a 
                  class="nav-link " 
                  (click)="onSelect('Tools')" 
                  [class.active]="selectedTab === 'Tools'"
                  role="tab" 
                  [href]="urlToolsWeb" target="_blank"
                >
									<div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_tools.jpg">
										</div>
									</div>
                </a>
              </li>		

              <li class="nav-item">
                <a 
                  class="nav-link " 
                  (click)="onSelect('RDAPEmbedded')" 
                  [class.active]="selectedTab === 'RDAPEmbedded'"
                  role="tab" 
                  data-toggle="tab"
                >
									<div class="media">
										<div class="avatar">
											<img class="img" src="assets/img/iconos_rdapembedded.jpg">
										</div>
									</div>
                </a>
              </li>
						</ul>
					</div>
					<div *ngIf="selectedTab != 'RDAPWEB' && selectedTab != 'RDAPEmbedded' && selectedTab != 'Tools'"  class="col-md-8">
						<div class="tab-content">						
						  <div *ngIf="selectedTab === 'WHOIS'" class="tab-pane active" id="tWhois">
								<div *ngIf="!loadingWhois" class="blockquote  blockquote-small">
									<pre id="whois">{{infoWhois}}</pre>
                </div>
                <div *ngIf="loadingWhois" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
							</div>											
							<div *ngIf="selectedTab === 'IRR'" class="tab-pane active" id="tIrr">
								<div *ngIf="!loadingIRR" class="blockquote  blockquote-small">
									<pre id="irr">{{infoIrr}}</pre>
                </div>
                <div *ngIf="loadingIRR" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
							</div>
							<div *ngIf="selectedTab === 'RDAP'" class="tab-pane active" id="tRdap">
								<div  *ngIf="!loadingRdap" class="blockquote  blockquote-small">
									<pre>{{infoRdapJson | json}}</pre>
                </div>
                <div *ngIf="loadingRdap" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedTab === 'RDAPWEB'"  class="col-md-10">
            <div class="tab-content">	
							<div *ngIf="selectedTab === 'RDAPWEB'" class="tab-pane active" id="tRdapWeb">
								<div *ngIf="!loadingRdapWeb" class="col-rdap">
                  <a id="rdapWeb" href="{{infoRdapWeb}}" target="_blank">
                    <h3>{{infoRdapWeb}}</h3>                    
                  </a>
                  <!--<iframe class="iframeRDAP" id="theFrame" [src]="infoRdapWeb" title="rdapWeb"></iframe>-->
                </div>
                <div *ngIf="loadingRdapWeb" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
							</div>
						</div>
          </div>

          <div *ngIf="selectedTab === 'Tools'"  class="col-md-10">
            <div class="tab-content">	
							<div *ngIf="selectedTab === 'Tools'" class="tab-pane active" id="tToolsWeb">
								<div *ngIf="!loadingToolsWeb" class="col-rdap">
                  <a id="toolsWeb" href="{{infoToolsWeb}}" target="_blank">
                    <h3>{{infoToolsWeb}}</h3>                    
                  </a>
                 </div>
                <div *ngIf="loadingToolsWeb" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
							</div>
						</div>
          </div>


          <div *ngIf="selectedTab === 'RDAPEmbedded'"  class="col-md-10">
            <div class="tab-content">	
							<div *ngIf="selectedTab === 'RDAPEmbedded'" class="tab-pane active" id="tRdapEmbedded">
								<div *ngIf="!loadingRdapEmbedded" class="col-rdap">
                  <!--<a id="rdapEmbedded" href="{{infoRdapEmbedded}}" target="_blank">
                    <h3>{{infoRdapEmbedded}}</h3>                    
                  </a>-->
                  <iframe class="iframeRDAP" id="theFrame" [src]="infoRdapEmbedded" title="rdapEmbedded"></iframe>
                </div>
                <div *ngIf="loadingRdapEmbedded" class=" text-center m-t-xs m-b-sm">
                  <img alt="logo" src="assets/img/1.gif" />
                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  
</div>


