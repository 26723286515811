<nav
  class="navbar navbar-color-on-scroll navbar-transparent    fixed-top  navbar-expand-lg "
  color-on-scroll="100" id="sectionsNav">
  <div class="container">
    <div class="navbar-translate">      
      <button class="navbar-toggler" type="button" data-toggle="collapse"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="sr-only">Toggle navigation</span> <span
          class="navbar-toggler-icon"></span> <span
          class="navbar-toggler-icon"></span> <span
          class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="dropdown nav-item">
          <a class="nav-link" routerLink="/home"> 
            <i class="material-icons">home</i> 
            {{ 'MENU.Links.Home' | translate }}
          </a>
        </li>  
        <li class="dropdown nav-item">
          <a class="nav-link" routerLink="/search"> 
            <i class="material-icons">search</i> 
            {{ 'MENU.Links.Search' | translate }}
          </a>
        </li>  
        <li class="dropdown nav-item">
          <a class="nav-link" routerLink="/irr"> 
            <i class="material-icons">zoom_in</i> 
            {{ 'MENU.Links.IRR' | translate }}
          </a>
        </li>
        <li class="dropdown nav-item">
          <a class="nav-link" target="_blank" href={{linkTools}}> 
            <i class="material-icons">settings</i> 
            {{ 'MENU.Links.TOOLS' | translate }}
          </a>
        </li>
        <li class="dropdown nav-item">
          <a class="nav-link" target="_blank" href={{linkDoc}}> 
            <i class="material-icons">file_copy</i> 
            {{ 'MENU.Links.Documentacion' | translate }}
          </a>
        </li>
        <li class="dropdown nav-item">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true"> 
            <i class="material-icons">language</i> 
            {{ 'MENU.Links.Language' | translate }}
          </a>  
          <div class="dropdown-menu dropdown-with-icons">
            <a class="dropdown-item" (click)="cambiarLenguaje('es')"> {{ 'MENU.Links.Esp' | translate }}</a> 
            <a class="dropdown-item" (click)="cambiarLenguaje('pt')"> {{ 'MENU.Links.Por' | translate }}</a> 
            <a class="dropdown-item" (click)="cambiarLenguaje('en')"> {{ 'MENU.Links.Eng' | translate }} </a>  
          </div>
        </li>  
        <li class="button-container nav-item iframe-extern">
          <a target="_blank" class="btn  btn-rose   btn-round btn-block" href={{linkMiLacnic}}> 
            <i class="material-icons">layers</i> 
            MiLACNIC
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
