import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {  Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { RDAPServiceService } from '../rdapservice.service';
import { AppSettings} from '../app.settings';
import { Utilities } from '../utilities';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  
  linkDocumentacion: string = AppSettings.ZENDESK
  selectedTab = "WHOIS";
  myIp : string;
  loading : boolean = true;
  cantLoading: number;
  error: boolean = false; 
  errorStr: string = "";

  query: string = "";
  infoWhois: string;
  infoIrr: string;
  infoRdap: string;
  infoRdapJson;
  infoRdapWeb: SafeResourceUrl;
  infoToolsWeb: SafeResourceUrl;
  infoRdapEmbedded: SafeResourceUrl;
  noResultados: boolean;

  loadingWhois: boolean = false;
  loadingIRR: boolean = false;
  loadingRdap: boolean = false;
  loadingRdapWeb: boolean = false;
  loadingRdapEmbedded: boolean = false;

  loadingToolsWeb: boolean = false;

  lang: string = "";

  urlRDAPWeb: SafeResourceUrl;
  urlToolsWeb: SafeResourceUrl;
  
  constructor(private rdapService: RDAPServiceService,  private router: Router, private translate: TranslateService, private sanitizer: DomSanitizer, private route: ActivatedRoute) { 
    this.selectedTab = "WHOIS";
    //si tengo info de search hago el search
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {search: string};
    if (state != undefined && state.search != null && state.search != "") {
      this.query = state.search;
      
    }

    var searchParam = this.route.snapshot.queryParamMap.get("id");
    if (searchParam != undefined && searchParam != null && searchParam != "") {
      this.query = searchParam;
    }
      

    this.cargarLenguaje();
  }

  ngOnInit(): void {
    
    this.loading = false;
    this.noResultados = true;



    if (this.query != undefined && this.query != null && this.query != "") {
      this.searchInfo(this.query);
    } 

  }

  


  cargarLenguaje(){
   
    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    
    if(localStorage.getItem('lenguaje') != null) {
      this.translate.use(localStorage.getItem('lenguaje'));
      this.lang = localStorage.getItem('lenguaje')
    } else {
      this.translate.use(this.translate.getDefaultLang());
      this.lang = this.translate.getDefaultLang();
    }
    
  }

  onSelect(tab: string): void {
    this.selectedTab = tab;
  }

  onClickBtnSearch(): void {  
    
    this.error = false;
    this.errorStr = "";
    if(this.query == null || this.query == ""){
      this.error = true;
      this.errorStr = this.translate.instant("GENERAL.Errores.Vacio");
    } else {
      this.searchInfo(this.query);
      this.router.navigate(['/search'], { queryParams: { id: this.query } });      
    }
  }

  onClickSearch(): void {  
    
    this.error = false;
    this.errorStr = "";
    this.noResultados = true;
    //valido que el texto NO sea vacío.
    if(this.query == null || this.query == ""){
      this.error = true;
      this.errorStr = this.translate.instant("GENERAL.Errores.Vacio");
    } else {
      Utilities.log('busco!!!');
      this.searchInfo(this.query);
    }
  }

  searchInfo(queryStr : string) {
    this.cantLoading = 0;
    //this.loading = true;
    this.noResultados = false;

    this.loadingWhois = true;
    this.loadingIRR = true;
    this.loadingRdap = true;
    this.loadingRdapWeb = true;
    this.loadingToolsWeb = true;
    this.loadingRdapEmbedded = true;
    
    
    this.onGetRDAPWeb(queryStr);
    this.onGetRDAPEmbedded(queryStr);
    this.onGetRDAP(queryStr);
    this.onGetIRR(queryStr);
    this.onGetWhois(queryStr);

    this.onGetTools(queryStr);
    
  }

  onGetWhois(query : string): void {
    /*this.rdapService.getBuscarWhois(query).subscribe(
      res => this.parseWhoisOk(res),
      error => this.parseError(error)
    )*/
    let info: string;
    this.rdapService.getBuscarWhois(query).subscribe(
      res => this.parseWhoisOk(res),
      error => this.parseWhoisError(error));
      
    
  };

  onGetIRR(query : string): void {
    /*this.rdapService.getBuscarWhois(query).subscribe(
      res => this.parseWhoisOk(res),
      error => this.parseError(error)
    )*/
    let info: string;
    this.rdapService.getBuscarIRR(query, false).subscribe(
      res =>  this.parseIRROk(res),
      error => this.parseIRRError(error));
   
  };

  onGetTools(query : string): void {
    if (this.lang == null || this.lang == "") {
      this.lang = 'es';
    } 


    this.infoToolsWeb = AppSettings.TOOLS_BUSQUEDA + query;
    this.urlToolsWeb = this.sanitize(AppSettings.TOOLS_BUSQUEDA + query);

    this.cantLoading = this.cantLoading + 1;
    Utilities.log('toolsWeb '+ this.cantLoading);
    
    this.loadingToolsWeb = false;
    
  };

  onGetRDAPWeb(query : string): void {
    /*this.rdapService.getBuscarWhois(query).subscribe(
      res => this.parseWhoisOk(res),
      error => this.parseError(error)
    )*/
    if (this.lang == null || this.lang == "") {
      this.lang = 'es';
    } 

    /*let info: string = this.rdapService.getBuscarRDAPWeb(query) + "?lang=" + this.lang;
    
    this.infoRdapWeb = this.sanitize(info);*/
    
    this.infoRdapWeb = this.rdapService.getBuscarRDAPWeb(query);
    this.urlRDAPWeb = this.sanitize(this.rdapService.getBuscarRDAPWeb(query));

    this.cantLoading = this.cantLoading + 1;
    Utilities.log('rdapweb '+ this.cantLoading);
    
    this.loadingRdapWeb = false;
    
  };

  onGetRDAPEmbedded(query : string): void {
    /*this.rdapService.getBuscarWhois(query).subscribe(
      res => this.parseWhoisOk(res),
      error => this.parseError(error)
    )*/
    if (this.lang == null || this.lang == "") {
      this.lang = 'es';
    } 

    let info: string = this.rdapService.getBuscarRDAPEmbedded(query) + "?lang=" + this.lang;
    
    this.infoRdapEmbedded = this.sanitize(info);
    
    this.cantLoading = this.cantLoading + 1;
    Utilities.log('rdapweb '+ this.cantLoading);
    
    this.loadingRdapEmbedded = false;
    
  };

  onGetRDAP(query : string): void {
    this.rdapService.getBuscarRDAP(query).subscribe(
      res => this.parseRDAPOk(res),
      error => this.parseRDAPError(error)
    )
    
  };

  onGetMyIp(): void {
    this.rdapService.getBuscarMiIP().subscribe(
      res => this.parseGetBuscarMiIPOk(res),
      error => this.parseGetBuscarMiIPError(error)
    )
  }

  parseGetBuscarMiIPOk(response:any){   
    Utilities.log("ip:" + response.ip);
    this.myIp = response.ip;    
    this.loading = false;
  }

  

  

  parseRDAPOk(response:any) {
    
    this.infoRdap = JSON.stringify(response); 
    this.infoRdapJson = response;
    
    this.cantLoading = this.cantLoading + 1;
    Utilities.log('rdap '+ this.cantLoading);
    
    this.loadingRdap = false;
  }

  parseIRROk(response:string) {
    Utilities.log("irr:" + response);
    this.infoIrr = response; 
    
    this.cantLoading = this.cantLoading + 1;
    Utilities.log('irr '+ this.cantLoading);
    if (this.cantLoading == 4) {
      this.loading = false;
    }
    this.loadingIRR = false;
  }

  parseWhoisOk(response:any){   
    Utilities.log("resWhois:" + response);
    
    this.infoWhois = response; 
    
    this.cantLoading = this.cantLoading + 1;
    Utilities.log('whois '+ this.cantLoading);
    
    this.loadingWhois = false;
  }

  parseRDAPError(error:any){
    Utilities.log("errorRdap: " + error);
    this.error = true;
    this.errorStr = this.translate.instant("GENERAL.Errores.ErrorRDAP");
    
    
    this.loadingRdap = false;
  }

  parseWhoisError(error:any){
    Utilities.log("error: " + error);
    this.error = true;
    this.errorStr = this.translate.instant("GENERAL.Errores.ErrorWHOIS");
    
    //this.loading = false;
    this.loadingWhois = false;
  }

  parseIRRError(error:any){
    Utilities.log("error: " + error);
    this.error = true;
    this.errorStr = this.translate.instant("GENERAL.Errores.ErrorIRR");
    
    //this.loading = false;
    this.loadingIRR = false;
  }

  parseGetBuscarMiIPError(error:any){
    Utilities.log("error: " + JSON.stringify(error));
    this.myIp = "";
    this.loading = false;
  }

  sanitize(url: string) {
    Utilities.log("url"+ url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  

 



}
