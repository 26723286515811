import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import * as io from 'socket.io-client';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AppSettings } from './app.settings';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root',
})
export class RDAPServiceService {
  private contentHeadersUrlEncoded: HttpHeaders;

  constructor(private http: HttpClient) {
    this.contentHeadersUrlEncoded = new HttpHeaders();
  }

  public getBuscarMiIP(): Observable<any> {
    const url = AppSettings.SERVICE_IP_API_URL;
    const options = {
      headers: new HttpHeaders({
        Accept:
          'text/html,application/xhtml+xml,application/xml, application/json',
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  public getBuscarWhois(query: string): Observable<any> {
    const url = AppSettings.WHOIS_LACNIC_NET;
    let queryWhois: string = Utilities.diferenciasAsdeEntity(query);
    let response: string = '';
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html',
        'Content-Type': 'text/plain',
      }),
    };
    Utilities.log('urlWhois: ' + url + queryWhois);

    return this.http
      .get(url + queryWhois, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  public getBuscarIRR(query: string, flags: boolean): Observable<string> {
    const url = AppSettings.IRR_LACNIC_NET;
    let response: string = '';

    let queryIrr: string = Utilities.queryIrr(query, flags);
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/html',
        'Content-Type': 'text/plain',
      }),
    };
    Utilities.log('urlIrr: ' + url + queryIrr);
    return this.http
      .get(url + queryIrr, { responseType: 'text' })
      .pipe(catchError(this.handleError));

    /*
    response = '%  No entries found for the selected source(s).\n';

    return of(Utilities.truncarRespuesta(response)); 
    */
  }

  public getBuscarRDAPWeb(query: string): string {
    let queryRdapWeb: string = Utilities.diferenciasAsdeEntity(query);

    let response: string = '';

    if (isNaN(Number(queryRdapWeb)) == false) {
      response =
        AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
        AppSettings.RDAP_WEB_AUTNUM +
        queryRdapWeb;
    } else {
      if (queryRdapWeb.includes('.') || queryRdapWeb.includes(':')) {
        response =
          AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
          AppSettings.RDAP_WEB_IP +
          queryRdapWeb;
      } else {
        response =
          AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
          AppSettings.RDAP_WEB_ENTITY +
          queryRdapWeb;
      }
    }

    return response;
  }

  public getBuscarRDAPEmbedded(query: string): string {
    let queryRdapWeb: string = Utilities.diferenciasAsdeEntity(query);

    let response: string = '';

    if (isNaN(Number(queryRdapWeb)) == false) {
      response =
        AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
        AppSettings.RDAP_EMBEDDED_AUTNUM +
        queryRdapWeb;
    } else {
      if (queryRdapWeb.includes('.') || queryRdapWeb.includes(':')) {
        response =
          AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
          AppSettings.RDAP_EMBEDDED_IP +
          queryRdapWeb;
      } else {
        response =
          AppSettings.HTTPS_RDAP_WEB_LACNIC_NET +
          AppSettings.RDAP_EMBEDDED_ENTITY +
          queryRdapWeb;
      }
    }

    return response;
  }

  public getBuscarRDAP(query: string): Observable<any> {
    const url = AppSettings.URL_RDAP_WS;
    let queryRdap: string = Utilities.queryRDAP(query);

    let response: string = '';

    const options = {
      headers: new HttpHeaders({
        Accept:
          'text/html,application/xhtml+xml,application/xml, application/json',
        'Content-Type': 'application/json',
      }),
    };
    Utilities.log('urlRdap: ' + url + queryRdap);
    return this.http
      .get(url + queryRdap)
      .pipe(catchError(this.handleErrorRDAP));

    //return response;
  }

  private handleErrorRDAP(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError('Error en la acceso al servidor');
    } else {
      if (error.status == 404) {
        return of(error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, ` + `body was: ${error.error}`
        );
        // Return an observable with a user-facing error message.
        return throwError('Error en la acceso al servidor');
      }
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Error en la acceso al servidor');
  }
}
