import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppSettings} from '../app.settings';
import { Utilities } from '../utilities';
import { RDAPServiceService } from '../rdapservice.service';

@Component({
  selector: 'app-irr',
  templateUrl: './irr.component.html',
  styleUrls: ['./irr.component.css']
})
export class IrrComponent implements OnInit {
  linkDocumentacion: string = AppSettings.ZENDESK
  //array objectos
  arrIrrObjs = AppSettings.IRROBJECTS;

  error: boolean = false; 
  errorStr: string = "";
  noResultados: boolean;
  loading : boolean = true;

  selectedTab = "IRR";

  query : string;
  infoIrr : string;

  //flags
  flgIdent: boolean = false;
  flgK: boolean = false;
  flgX: boolean = false;
  flgL: boolean = false;
  flgM: boolean = false;
  flgTempl: boolean = false;
  flgS: boolean = false;
  flgTipoValor: boolean = false;
  //Campos
  strIdentificador: string;
  strIPs: string;
  strIPsMenosEspecif: string;
  strIPsMasEspecif: string;
  strTemplate;
  strFuente: string;
  strTipoValor: string;

  buscarAuto : boolean = false;

  constructor( private rdapService: RDAPServiceService, private router: Router, private translate: TranslateService, private route: ActivatedRoute) { 
    
    this.cargarLenguaje();

    var searchParam = this.route.snapshot.queryParamMap.get("search");
    var flagParam = this.route.snapshot.queryParamMap.get("flag");
    var typeParam = this.route.snapshot.queryParamMap.get("type");
    var templateParam = this.route.snapshot.queryParamMap.get("template");
    
    var tengoSearch : Boolean = false;
    if (searchParam != undefined && searchParam != null && searchParam != "") {
      tengoSearch = true;

    };
    if ((flagParam != undefined && flagParam != null && flagParam != "" ) && (tengoSearch)) {
      switch (flagParam.toUpperCase()) {
        case 'K':
          this.flgK = true;
          this.flgIdent = true;
          this.strIdentificador = searchParam;
          this.buscarAuto = true;
          break;
        case 'X':
          this.flgX = true;
          this.strIPs = searchParam;
          this.buscarAuto = true;
          break;
        case 'L':
          this.flgL = true;
          this.strIPsMenosEspecif = searchParam;
          this.buscarAuto = true;
          break;
        case 'M':
          this.flgM = true;
          this.strIPsMasEspecif = searchParam;
          this.buscarAuto = true;
          break;
        case 'S':
          this.flgS = true;
          this.strFuente = searchParam;
          this.buscarAuto = true;
          break;
        case 'T':
          var listTempl = AppSettings.TEMPLATE_LIST;

          if (listTempl.includes(templateParam)) {
           
            this.flgTempl = true;
            this.strTemplate = templateParam;
            this.strIdentificador = searchParam;
            this.buscarAuto = true;
          } 
          break;
        case 'I':
          if (typeParam != undefined && typeParam != null && typeParam != "") {
            this.flgTipoValor = true;
            this.strTipoValor = typeParam + ' ' + searchParam;
            this.buscarAuto = true;
          }
          break;
      }        
    } else {
      if (tengoSearch) {
        this.flgIdent = true;
        this.strIdentificador = searchParam;
        this.buscarAuto = true;
      }
    };


    
    
  }

  ngOnInit(): void {
    
    this.loading = false;
    this.noResultados = true;

    if (this.buscarAuto) {
      this.buscarAuto = false;
      //espero que se inicialicen los idiomas
      this.translate.get('GENERAL.Errores.Vacio').subscribe((translated: string) => {        
        this.onClickSearch();
      });
    }
  }  

  cargarLenguaje(){
   
    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    
    if(localStorage.getItem('lenguaje') != null) {
      this.translate.use(localStorage.getItem('lenguaje'));
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }
    
  }

  onChangeFlags(flag: string): void {
    switch (flag) {
      case 'identificador':
        if (this.strIdentificador == null || this.strIdentificador == "") {
          this.flgIdent = false;
        } else {
          this.flgIdent = true;
        }
        break;
      case 'flgK':
        this.flgK = !this.flgK;
        break;
      case 'strIPs':
        if (this.strIPs == null || this.strIPs == "") {
          this.flgX = false;
        } else {
          this.flgX = true;
        }
        break;
      case 'strIPsMenosEspecif':
        if (this.strIPsMenosEspecif == null || this.strIPsMenosEspecif == "") {
          this.flgL = false;
        } else {
          this.flgL = true;
        }
        break;
      case 'strIPsMasEspecif':
        if (this.strIPsMasEspecif == null || this.strIPsMasEspecif == "") {
          this.flgM = false;
        } else {
          this.flgM = true;
        }
        break;
      case 'strFuente':
        if (this.strFuente == null || this.strFuente == "") {
          this.flgS = false;
        } else {
          this.flgS = true;
        }
        break;
      case 'strTipoValor':
        if (this.strTipoValor == null || this.strTipoValor == "") {
          this.flgTipoValor = false;
        } else {
          this.flgTipoValor = true;
        }
        break;
      case 'template':
        if (this.strTemplate == null || this.strTemplate == "") {
          this.flgTempl = false;
        } else {
          this.flgTempl = true;
        }
        break;

    }

  }

  onClear(flag: string): void {
    switch (flag) {
      case 'identificador':        
        this.flgIdent = false;
        this.strIdentificador = "";       
        break;
      case 'flgK':        
        this.flgK = false;         
        break;
      case 'strIPs':        
        this.flgX = false;
        this.strIPs = "";       
        break;
      case 'strIPsMenosEspecif':        
        this.flgL = false;
        this.strIPsMenosEspecif = "";       
        break;
      case 'strIPsMasEspecif':        
        this.flgM = false;
        this.strIPsMasEspecif = "";       
        break;
      case 'strFuente':        
        this.flgS = false;
        this.strFuente = "";       
        break;
      case 'strTipoValor':        
        this.flgTipoValor = false;
        this.strTipoValor = "";       
        break;
      case 'strTemplate':        
        this.flgTempl = false;
        this.strTemplate = "";       
        break;
    }

  }

  onClickBtnSearch(): void { 

    var searchParam = "";
    var flagParam = "";
    var typeParam = "";
    var templateParam = "";

    this.error = false;
    this.errorStr = "";

    this.query = this.getQueryIRR();
    Utilities.log("query:"+ this.query);
    //valido que el texto NO sea vacío.
    if(this.query == null || this.query == ""){
      this.error = true;
      this.errorStr = this.translate.instant("GENERAL.Errores.Vacio");      
      
    } else {
      
      this.error = this.validoFiltros();
      if (this.error == false) {

        //template
        if (this.flgTempl) {
          templateParam = this.strTemplate;
        };
        //Chequeo flags solo elijo una
        if (this.flgTipoValor) {
          flagParam = "I";
          let arrTipoVal: string[] = this.strTipoValor.replace('  ', ' ').split(" ");
          typeParam = arrTipoVal[0];
          searchParam = arrTipoVal[1];          
        } else if (this.flgS) {
          flagParam = "S";
          searchParam = this.strFuente;
          typeParam = "";          
        } else if (this.flgM) {
          flagParam = "M";
          searchParam = this.strIPsMasEspecif;
          typeParam = "";
        } else if (this.flgL) {
          flagParam = "L";
          searchParam = this.strIPsMenosEspecif;
          typeParam = "";
        } else if (this.flgX) {
          flagParam = "X";
          searchParam = this.strIPs;
          typeParam = "";
        } else if (this.flgK) {
          flagParam = "K";
          searchParam = this.strIdentificador;
          typeParam = "";
        } else {
          flagParam = "";
          searchParam = this.strIdentificador;
          typeParam = "";
        }

        let params = {};
        if (searchParam !="") {
          params['search'] = searchParam;
        }
        if (flagParam !="") {
          params['flag'] = flagParam;
        }
        if (typeParam !="") {
          params['type'] = typeParam;
        }
        if (templateParam !="") {
          params['template'] = templateParam;
        }

        this.searchInfo(this.query);
        this.router.navigate(['/irr'], { queryParams: params });    
      }
    }
  }  

  onClickSearch(): void {  
    
    this.error = false;
    this.errorStr = "";
    this.noResultados = true;
    
    this.query = this.getQueryIRR();
    Utilities.log("query:"+ this.query);
    //valido que el texto NO sea vacío.
    if(this.query == null || this.query == ""){
      this.error = true;
      this.errorStr = this.translate.instant("GENERAL.Errores.Vacio");      
      
    } else {
      
      this.error = this.validoFiltros();
      if (this.error == false) {
        this.searchInfo(this.query);
      }
    }
  }

  validoFiltros() : boolean {
    let esError : boolean = false;
    let strValor: string;
    let strError: string;
    let filtrosVacios: boolean = true;
    //valido Identificador
    if (this.flgIdent) {
      filtrosVacios = false;
      strValor = this.strIdentificador.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      }      
    }
    //valido IPs
    if (this.flgX) {
      filtrosVacios = false;
      strValor = this.strIPs.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      }      
    }
    //valido IPs Menos especif
    if (this.flgL) {
      filtrosVacios = false;
      strValor = this.strIPsMenosEspecif.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      }      
    }
    //valido IPs MAs especif
    if (this.flgM) {
      filtrosVacios = false;
      strValor = this.strIPsMasEspecif.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      }
    }
    //valido fuente
    if (this.flgS) {
      filtrosVacios = false;
      strValor = this.strFuente.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      }
    }
    //valido 
    if (this.flgTipoValor) {
      filtrosVacios = false;
      strValor = this.strTipoValor.replace('  ', ' ');
      strError = Utilities.validoInputIrr(strValor);
      if (strError != '') {
        this.errorStr = this.translate.instant(strError);
        esError = true;
      } else {
        strError = Utilities.validoInputIrrTipoValor(strValor)
        if (strError != '') {
          this.errorStr = this.translate.instant(strError);
          esError = true;
        }
      }
    }

    //si no hay error y no tengo ningún filtro chequeo templates
    if (!esError && filtrosVacios) {
      if(this.strTemplate == null || this.strTemplate == "") {
        esError = true;
        this.errorStr = this.translate.instant("GENERAL.Errores.Vacio");
      }
    }

    return esError;
  }

  

  getQueryIRR() : string {
    let response: string = "";

    //flag K
    if (this.flgK) {
      response = response + '-k';
    }
    //identificador
    if (this.flgIdent) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + this.strIdentificador;
    }
    //fuente
    if (this.flgS) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-s ' + this.strFuente;
    }
    //ip menos especificas
    if (this.flgL) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-L ' + this.strIPsMenosEspecif;
    }
    //ip mas especificas
    if (this.flgM) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-M ' + this.strIPsMasEspecif;
    } 
    //templates
    if (this.flgTempl) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-t ' + this.strTemplate;
    } 
    //ips
    if (this.flgX) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-x ' + this.strIPs;
    }
    //tipo valor
    if (this.flgTipoValor) {
      if (response != '') {
        response = response + ' ';
      }
      response = response + '-i ' + this.strTipoValor;
    }

    return response;
  }

  searchInfo(queryStr : string) {    
    this.loading = true;
    this.noResultados = false; 
    /*
    if (this.captchaSubscription) {
      this.captchaSubscription.unsubscribe();
    }
    this.captchaSubscription = this.recaptchaV3Service.execute('searchAction')
      .subscribe((token) => {
        this.captchaToken = token;
        Utilities.log('CaptchaToken:' + token);
        this.onGetIRR(queryStr);  
      },
      (error) => {
        this.loading = false;
        this.noResultados = true;
        this.captchaToken = '';
        this.errorStr =  error;
        this.error = true;
      },
    );
    */

    this.onGetIRR(queryStr);    
    
  }

  onGetIRR(query : string): void {    
    let info: string;
    this.rdapService.getBuscarIRR(query, true).subscribe(
      res => this.parseIRROk(res),
      error => this.parseIRRError(error));
    //this.infoIrr = info; 
    //this.loading = false;  
  };

  parseIRROk(response:string) {
    Utilities.log("irr:" + response);
    this.infoIrr = response;
    this.loading = false;
  
  }

  parseIRRError(error:any){
    Utilities.log("error: " + error);
    this.error = true;
    this.errorStr = this.translate.instant("GENERAL.Errores.ErrorIRR");
    
    this.loading = false;
    
  }

}
