export class AppSettings {
  public static debug: boolean = false;

  public static TOOLS: string = 'https://tools.labs.lacnic.net/tools';
  public static TOOLS_BUSQUEDA: string =
    'https://tools.labs.lacnic.net/tools/dashboard?id=';

  public static URL_RDAP_WS: string = 'https://rdap-redirect.lacnic.net/rdap';
  public static SERVICE_IP_API_URL: string =
    'https://rdap-redirect.lacnic.net/rdap/info/myip';
  public static WHOIS_LACNIC_NET: string =
    'https://rdap-redirect.lacnic.net/rdap/whois/';
  public static IRR_LACNIC_NET: string =
    'https://rdap-redirect.lacnic.net/rdap/irr/';
  public static HTTPS_RDAP_WEB_LACNIC_NET: string =
    'https://rdap-web.lacnic.net/';

  public static RDAP_WEB_ENTITY: string = 'rdap/entity/';
  public static RDAP_WEB_AUTNUM: string = 'rdap/autnum/';
  public static RDAP_WEB_IP: string = 'rdap/ip/';

  public static ENDPOINT_AUTNUM: string = '/autnum/';
  public static ENDPOINT_ENTITY: string = '/entity/';
  public static ENDPOINT_IP: string = '/ip/';

  public static RDAP_EMBEDDED_ENTITY: string = 'rdap/query-entity/';
  public static RDAP_EMBEDDED_AUTNUM: string = 'rdap/query-autnum/';
  public static RDAP_EMBEDDED_IP: string = 'rdap/query-ip/';

  public static CAPTCHA_KEY: string =
    '6LdQOsEUAAAAAHlRaAx8Gb8hr6S1ILFmzoPwc9Ew';

  public static AS: string = 'AS';
  public static RESPUESTA_TRUNCADA: string =
    '\n\n% Respuesta truncada en 5000 caracteres';

  public static IRROBJECTS = [
    { value: 'aut-num', display: 'aut-num' },
    { value: 'mntner', display: 'mntner' },
    { value: 'route', display: 'route' },
    { value: 'route6', display: 'route6' },
    { value: 'person', display: 'person' },
    { value: 'as-set', display: 'as-set' },
  ];

  public static TEMPLATE_LIST = [
    'aut-num',
    'mntner',
    'route',
    'route6',
    'person',
    'as-set',
  ];

  public static ZENDESK: string = 'https://lacnic.zendesk.com/hc/es';
  public static WWWLACNICNET: string = 'https://www.lacnic.net';
  public static MILACNIC: string = 'https://milacnic.lacnic.net';
  public static POLITICAPRIVACIDAD: string =
    'https://www.lacnic.net/politica-privacidad';
  public static HACKATHON: string = 'https://hackathon.dev.lacnic.net';
}
