import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '../utilities';

@Component({
  selector: 'app-special-thanks',
  templateUrl: './special-thanks.component.html',
  styleUrls: ['./special-thanks.component.css']
})
export class SpecialThanksComponent implements OnInit {

 

  constructor(private translate: TranslateService) { 
    this.cargarLenguaje();
  }

  cargarLenguaje(){
    Utilities.log("[Thanks] - cargarLenguaje: Start");

    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');

    
    if(localStorage.getItem('lenguaje') != null) {
      this.translate.use(localStorage.getItem('lenguaje'));
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }

    Utilities.log("[Thanks] - cargarLenguaje: Finish");
  }

  ngOnInit(): void {
  }

}
